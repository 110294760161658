<template>
  <DashboardAuthLayout>
    <template #header>
      <div class="mt-3 relative"><img src="@/assets/images/careercolor.svg" alt="Logo" class="mt-3" /></div>
    </template>

    <div v-if="pageLoaded" class="my-9 p-4 md:p-9 bg-white rounded w-full">
      <div class="flex flex-col justify-between items-center">
        <div class="flex flex-col">
          <h3 class="font-cooper font-medium text-3xl text-[#090E11]">Complete your profile</h3>
          <div class="opacity-90 text-indigo-950 text-base font-normal">Hi {{ userDetails?.name }}, welcome to the Family!</div>
        </div>
        <div class="w-10/12 h-36 p-3 bg-gray-50 justify-center items-center inline-flex">
          <img class="w-8/12 h-28" :src="userDetails?.employer?.logo" />
        </div>
      </div>
      <div v-if="userDetails.name">
        <form>
          <div class="pt-4 flex flex-col">
            <label
              for="password-icon"
              class="block mb-2 text-sm text-brand-dark-blue/80 font-semibold"
              >Create Password</label
            >
            <div class="relative">
              <div
                class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
              >
                <LockSvg class="text-[#090E11]/20" />
              </div>
              <input
                :type="showPassword ? 'text' : 'password'"
                id="password-icon"
                class="bg-[#F2F2F2] border-0 text-[#090E11] text-sm rounded-lg focus:ring-0 focus:border-0 block w-full px-10 p-3"
                v-model="userDetails.password"
              />
              <div class="absolute inset-y-0 right-0 flex items-center pr-3">
                <div
                  class="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                >
                  <EyeSlashSvg
                    v-if="!showPassword"
                    @click="showPassword = true"
                    fill="black"
                  />
                  <EmailSvg v-else @click="showPassword = false" />
                </div>
              </div>
            </div>
          </div>

          <div class="pt-4 flex flex-col">
            <label
              for="password-icon-confirm"
              class="block mb-2 text-sm text-brand-dark-blue/80 font-semibold"
              >Confirm Password</label
            >
            <div class="relative">
              <div
                class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
              >
                <LockSvg class="text-[#090E11]/20" />
              </div>
              <input
                :type="showConfirmPassword ? 'text' : 'password'"
                id="password-icon-connfirm"
                class="bg-[#F2F2F2] border-0 text-[#090E11] text-sm rounded-lg focus:ring-0 focus:border-0 block w-full px-10 p-3"
                v-model="userDetails.password_confirmation"
              />
              <div class="absolute inset-y-0 right-0 flex items-center pr-3">
                <div
                  class="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                >
                  <EyeSlashSvg
                    v-if="!showConfirmPassword"
                    @click="showConfirmPassword = true"
                    fill="black"
                  />
                  <EmailSvg v-else @click="showConfirmPassword = false" />
                </div>
              </div>
            </div>
          </div>

          <div class="pt-4">
            <button
              type="button"
              @click="handleRegistration"
              class="w-full bg-brand-primary text-white p-4 rounded-lg"
            >
              Submit <loading :loading="loading" />
            </button>
          </div>
        </form>
      </div>

        <div v-else>No invited account was found with this record, you can refresh this page or contact
          your inviter to resend it.
        </div>
    </div>
    <div v-else class="my-9 p-4 md:p-9 bg-white rounded w-full">
      <div className="flex justify-center items-center font-extrabold" >LOADING...</div>
    </div>

  </DashboardAuthLayout>
</template>

<script setup>
import DashboardAuthLayout from '@/layouts/DashboardAuthLayout.vue';
import LockSvg from "@/assets/icons/lock.svg?inline";
import EmailSvg from "@/assets/icons/email.svg?inline";
import EyeSlashSvg from "@/assets/icons/eye-slash.svg?inline";
import { ref, computed, onBeforeMount } from 'vue';
import {  useRoute, useRouter } from 'vue-router';
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import { errorMessage } from "@/utils/helper";

const store = useStore();
const route = useRoute();
const toast = useToast();
const router = useRouter();
const props = defineProps({
  registrationType: String,
});
const userDetails = ref({
  name: '',
  email: '',
  employer: {logo: ''},
  uuid: '',
  password: '',
  password_confirmation: ''
});
const disabled = ref(true);
const loading = ref(true);
const showConfirmPassword = ref(false);
const showPassword = ref(false);
const pageLoaded = ref(false);

const checkDisability = computed(() => {
  return (userDetails.value.password && userDetails.value.password_confirmation !== '')
    ? (disabled.value = false)
    : (disabled.value = true);
});

const handleRegistration = async() => {
  try {
    loading.value = true;
    const data = await store.dispatch("auth/createInvitePassword", userDetails.value);

    toast.success(data.message, {
      timeout: 3000,
      hideProgressBar: true,
    });

    return router.push("/dashboard");
  } catch (error) {
    toast.error(errorMessage(error), {
      timeout: 3000,
      hideProgressBar: true,
    });
    loading.value = false;
  }
}


onBeforeMount(async () => {
  try {
    const {status, user, employer} = await store.dispatch('auth/checkInvite', route.params.uuid);
    if (status) {
      userDetails.value = {
        name: user.name.split(' ')[0],
        email: user.email,
        employer: employer,
        uuid: user.uuid,
        password: '',
        password_confirmation: ''
      };
    }
  } catch (error) {
    toast.error(errorMessage(error), {
      timeout: 3000,
      hideProgressBar: true,
    });
  } finally {
    pageLoaded.value = true;
  }
});
</script>

<style></style>
